import { builder, Builder } from '@builder.io/react';
import dynamic from 'next/dynamic';
import React from 'react';
import { Nav } from "./components/Nav"; // Adjust the path as needed
import { GlobalOverlay } from './components/GlobalOverlay/GlobalOverlay'; // Adjust the path as needed
// import { NavMobile } from '@components/NavMobile';
import Footer from '@components/Footer';
import FooterMobile from '@components/FooterMobile';
import Resource from '@components/Resource';
import ResourceMobile from '@components/ResourceMobile';
import Sop from '@components/Sop';

import Faq from '@components/FAQ';
import TeamSection from '@components/TeamSection/TeamSection';
import { SharedNav } from '@components/SharedNav';
import HeroSectionRoot from '@components/HeroSection';
import ProsAndCons from '@components/ProsAndCons';
import ProsAndConsMobile from '@components/ProsAndConsMobile';
// import InstagramFeed from '@components/InstagramFeed/InstagramFeed';
import HeroSectionNav from '@components/HeroSectionNav';
import { SharedNav_2 } from '@components/SharedNav_2';
import HeroSectionVideo from '@components/HeroSectionVideo';
import OurSystems from '@components/OurSystems/OurSystems';
import { NavMobile } from '@components/SharedNavMobile';
import CalconicCalculator from '@components/CalconicCalculator/CalconicCalculator';
import ProductGrid from './components/ProductGrid/ProductGrid'
import ProductPage from './components/ProductGrid/ProductPage'
import IPMGrid from './components/IPMGrid/IPMGrid'
import IPMPage from './components/IPMGrid/IPMPage'
// import { Form } from '@builder.io/react-form'
import Form from './components/Form/Form'
// import ProfileCards from "./components/ProfileCards/ProfileCards";
import ProfileCardsC from "./components/ProfileCardsC/ProfileCardsC";

import ProductPages from "./components/ProductPage/ProductPage";
import IPMPages from "./components/IPMDetails/ProductPage";
import SolutionWorks from "./components/IPMDetails/SolutionWorks";
import CallButtonMobile from '@components/CallButtonMobile/CallButtonMobile';

import CallButton from './components/CallButton/CallButton';
import ProductPageMobile from './components/ProductPageMobile/ProductPageMobile';
import FormG from './components/Form_Mobile/Form_Mobile'

import Faqq from '@components/CustFaq/CustFaq';


import FormG2 from './components/Form_Mobile_White/Form_Mobile_White'

import Component from './components/New_resource/page';

// import ProfileCards from "./components/Prof_/Prof_";

import IPMGrid_ID from './components/IPMGrid_ID/IPMGrid_ID'
// import ProfileCards from "./components/Profile_Cards_Up/Profile_Cards_Up";

// import Sop__ from './components/SOP__/page';


import Sop2 from './components/Sop2';

import AboutUs from '@components/AboutUs/AboutUs';

// const DynamicCalconicCalculator = dynamic(
//   () => import('./components/CalconicCalculator/CalconicCalculator'),
//   { ssr: false }
// );
const DynamicCalconicCalculator = dynamic(
  () => import('./components/CalconicCalculator/DynamicCalconicCalculator'),
  { ssr: false }
);

const ProfileCards = dynamic(() => import('./components/Prof_/Prof_'));


const LazyProductPages = dynamic(() => import('./components/ProductPage/ProductPage'), {
  ssr: false,
});

const DynamicCalconicIframe = dynamic(
  () => import('./components/CalconicIframe/CalconicIframe'),
  { ssr: false }
);

builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY!)

Builder.registerComponent(
  dynamic(() => import('./components/Counter/Counter')),
  {
    name: 'Counter',
    inputs: [
      {
        name: 'initialCount',
        type: 'number',
      },
    ],
  }
)

Builder.registerComponent(GlobalOverlay, {
  name: 'Global Overlay',
  inputs: [
    {
      name: 'children',
      type: 'jsx',
      defaultValue: `<div></div>`,
    },
  ],
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'Overlay Content' } },
    },
  ],
});

Builder.registerComponent(Nav, {
  name: "Nav",
  inputs: [
    { name: "className", type: "string", defaultValue: "" },
    { name: "line", type: "string", defaultValue: "/img/line-2-17.svg" },
    { name: "to", type: "string", defaultValue: "Home-Mobile" },
  ],
});

Builder.registerComponent(NavMobile, {
  name: "NavMobile",
  inputs: [
    { name: "className", type: "string", defaultValue: "" },
    { name: "line", type: "string", defaultValue: "/img/line-2-17.svg" },
    { name: "to", type: "string", defaultValue: "Home-Mobile" },
  ],
});

Builder.registerComponent(Footer, {
  name: "Footer",
});

Builder.registerComponent(FooterMobile, {
  name: "FooterMobile",
});

Builder.registerComponent(Resource, {
  name: "Resource",
});

Builder.registerComponent(ResourceMobile, {
  name: "ResourceMobile",
});

Builder.registerComponent(Sop, {
  name: "Sop",
});

Builder.registerComponent(Sop2, {
  name: "Sop2",
});

Builder.registerComponent(Faq, {
  name: "Faq",
});

Builder.registerComponent(TeamSection, {
  name: "TeamSection",
});

Builder.registerComponent(HeroSectionRoot, {
  name: "HeroSection",
});

Builder.registerComponent(ProsAndCons, {
  name: "ProsAndCons",
});

Builder.registerComponent(ProsAndConsMobile, {
  name: "ProsAndConsMobile",
});

Builder.registerComponent(HeroSectionNav, {
  name: "HeroSectionNav",
});

Builder.registerComponent(OurSystems, {
  name: "OurSystem",
});

Builder.registerComponent(HeroSectionVideo, {
  name: "HeroSectionVideo",
});


// Define allowed HTML tags for the Overlay Trigger
type AllowedTags = 'div' | 'span' | 'button' | 'a' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface OverlayTriggerProps {
  tag?: AllowedTags;
  children?: React.ReactNode;
  className?: string;
  [key: string]: any; // For any additional props
}

const OverlayTrigger: React.FC<OverlayTriggerProps> = ({
  children,
  tag = 'div',
  className = '',
  ...props
}) => {
  const CustomTag = tag as keyof JSX.IntrinsicElements;
  return React.createElement(
    CustomTag,
    {
      className: `overlay-trigger ${className}`.trim(),
      ...props
    },
    children
  );
};

// Register the Overlay Trigger component
Builder.registerComponent(OverlayTrigger, {
  name: 'Overlay Trigger',
  inputs: [
    {
      name: 'tag',
      type: 'string',
      defaultValue: 'div',
      enum: ['div', 'span', 'button', 'a', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    },
    {
      name: 'className',
      type: 'string',
      defaultValue: 'text',
    },
  ],
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: '' } },
    },
  ],
  canHaveChildren: true,
});

Builder.registerComponent(SharedNav, {
  name: "SharedNav",
  inputs: [
    {
      name: "logoSrc",
      type: "string",
      defaultValue: "https://cdn.builder.io/api/v1/image/assets/TEMP/03b529fd8f1563ec8c5630d4ee62b758b4f401a3b4ef42fa7d4e3500fcf4fab6?apiKey=7d740121674b4225a077dc91176b5085&"
    },
    {
      name: "logoText",
      type: "string",
      defaultValue: "NeutraFog"
    },
    {
      name: "menuOpenIconSrc",
      type: "string",
      defaultValue: "https://cdn.builder.io/api/v1/image/assets/TEMP/70bd71e9838c9472fbec170f2fac80a2a9e15bbe1e09d39ac447d7056a1765ae?apiKey=7d740121674b4225a077dc91176b5085&"
    },
    {
      name: "menuClosedIconSrc",
      type: "string",
      defaultValue: "https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F1b5896a8db7d469fa5eda9cb86f9c6e7"
    },
    {
      name: "callIconSrc",
      type: "string",
      defaultValue: "https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F415cd4ab860f4cf9acab124056bf2da5"
    }
  ]
});

Builder.registerComponent(SharedNav, {
  name: "SharedNav",
  inputs: [
    {
      name: "navigationItems",
      type: "list",
      subFields: [
        {
          name: "name",
          type: "string"
        },
        {
          name: "url",
          type: "string"
        }
      ],
      defaultValue: [
        { name: 'about us', url: '/about-us' },
        { name: 'our systems', url: '/our-systems' },
        { name: 'IPM Products', url: '/ipm-products' },
        { name: 'subscriptions', url: '/subscriptions' },
        { name: 'resources', url: '/resources' },
      ]
    }
  ]
});

Builder.registerComponent(SharedNav, {
  name: "SharedNav",
  inputs: [
    {
      name: "socialIcons",
      type: "list",
      subFields: [
        {
          name: "icon",
          type: "string"
        },
        {
          name: "url",
          type: "string"
        }
      ],
      defaultValue: [
        {
          icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/b9432c8f4dba5ae15b3e77b14f7bfcb6dbd927ed53c2d70898c6f9cfed3d34c5?apiKey=7d740121674b4225a077dc91176b5085&',
          url: 'facebook',
        },
        {
          icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/1b13625d254bb1e6a39206b1b8404a9e19085ace9751113737555ea7169b8e96?apiKey=7d740121674b4225a077dc91176b5085&',
          url: 'instagram',
        },
        {
          icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/ccb7c17cee24581c9943cf911985cc54a72a000a840f7c251564dd9ac02d5c34?apiKey=7d740121674b4225a077dc91176b5085&',
          url: 'x',
        },
        {
          icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/12d30d25a11e3118cfaa3befd6f88b7453c2ed257720dcb64846f7e8795d7da1?apiKey=7d740121674b4225a077dc91176b5085&',
          url: 'youtube',
        },
      ]
    }
  ]
});





import CallButtonDesktop from './components/SharedNav/CallModal/CallButtonDesktop';

Builder.registerComponent(CallButtonDesktop, {
  name: "CallButtonDesktop",
  inputs: [
    {
      name: "modalTitle",
      type: "string",
      defaultValue: "Get In Touch",
    },
    {
      name: "modalDescription",
      type: "string",
      defaultValue: "Fill out this form and we'll get back to you as soon as possible.",
    },
    {
      name: "submitButtonText",
      type: "string",
      defaultValue: "Submit",
    },
    {
      name: "showLocationField",
      type: "boolean",
      defaultValue: true,
    },
  ],
});

Builder.registerComponent(SharedNav_2, {
  name: "SharedNav_2",
  inputs: [
    {
      name: "logoSrc",
      type: "string",
      defaultValue: "https://cdn.builder.io/api/v1/image/assets/TEMP/03b529fd8f1563ec8c5630d4ee62b758b4f401a3b4ef42fa7d4e3500fcf4fab6?apiKey=7d740121674b4225a077dc91176b5085&"
    },
    {
      name: "logoText",
      type: "string",
      defaultValue: "NeutraFog"
    },
    {
      name: "menuOpenIconSrc",
      type: "string",
      defaultValue: "https://cdn.builder.io/api/v1/image/assets/TEMP/70bd71e9838c9472fbec170f2fac80a2a9e15bbe1e09d39ac447d7056a1765ae?apiKey=7d740121674b4225a077dc91176b5085&"
    },
    {
      name: "menuClosedIconSrc",
      type: "string",
      defaultValue: "https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F1b5896a8db7d469fa5eda9cb86f9c6e7"
    },
    {
      name: "callIconSrc",
      type: "string",
      defaultValue: "https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F415cd4ab860f4cf9acab124056bf2da5"
    }
  ]
});

Builder.registerComponent(SharedNav_2, {
  name: "SharedNav_2",
  inputs: [
    {
      name: "navigationItems",
      type: "list",
      subFields: [
        {
          name: "name",
          type: "string"
        },
        {
          name: "url",
          type: "string"
        }
      ],
      defaultValue: [
        { name: 'about us', url: '/about-us' },
        { name: 'our systems', url: '/our-systems' },
        { name: 'IPM Products', url: '/ipm-products' },
        { name: 'subscriptions', url: '/subscriptions' },
        { name: 'resources', url: '/resources' },
      ]
    }
  ]
});

Builder.registerComponent(SharedNav_2, {
  name: "SharedNav_2",
  inputs: [
    {
      name: "socialIcons",
      type: "list",
      subFields: [
        {
          name: "icon",
          type: "string"
        },
        {
          name: "url",
          type: "string"
        }
      ],
      defaultValue: [
        {
          icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/b9432c8f4dba5ae15b3e77b14f7bfcb6dbd927ed53c2d70898c6f9cfed3d34c5?apiKey=7d740121674b4225a077dc91176b5085&',
          url: 'facebook',
        },
        {
          icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/1b13625d254bb1e6a39206b1b8404a9e19085ace9751113737555ea7169b8e96?apiKey=7d740121674b4225a077dc91176b5085&',
          url: 'instagram',
        },
        {
          icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/ccb7c17cee24581c9943cf911985cc54a72a000a840f7c251564dd9ac02d5c34?apiKey=7d740121674b4225a077dc91176b5085&',
          url: 'x',
        },
        {
          icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/12d30d25a11e3118cfaa3befd6f88b7453c2ed257720dcb64846f7e8795d7da1?apiKey=7d740121674b4225a077dc91176b5085&',
          url: 'youtube',
        },
      ]
    }
  ]
});



// ... other imports and registrations ...

Builder.registerComponent(CalconicCalculator, {
  name: 'Calconic Calculator',
  inputs: [
    {
      name: 'calculatorId',
      type: 'string',
      defaultValue: '6601976926da2c0029d92755'
    }
  ],
});

// Use DynamicCalconicCalculator for registration
Builder.registerComponent(DynamicCalconicCalculator, {
  name: 'Calconic Calculator3',
  inputs: [
    {
      name: 'calculatorId',
      type: 'string',
      defaultValue: '6601976926da2c0029d92755'
    }
  ],
});

Builder.registerComponent(DynamicCalconicIframe, {
  name: 'Calconic Calculator Iframe',
  inputs: [
    {
      name: 'calculatorId',
      type: 'string',
      defaultValue: '6601976926da2c0029d92755'
    }
  ],
});

Builder.registerComponent(ProductPage, {
  name: 'ProductPage',
  inputs: [
    {
      name: 'initialContainerWidth',
      type: 'number',
      defaultValue: 800,
    },
  ],
})

Builder.registerComponent(ProductGrid, {
  name: 'ProductGrid',
  inputs: [
    {
      name: 'products',
      type: 'list',
      subFields: [
        { name: 'id', type: 'number' },
        { name: 'name', type: 'string' },
        { name: 'price', type: 'number' },
        { name: 'image', type: 'string' }
      ],
      defaultValue: [
        {
          id: 1,
          name: 'T-250 System',
          price: 3459.00,
          image: '/placeholder.svg?height=200&width=200'
        },
        // ... you can add more default products here
      ]
    }
  ]
})





Builder.registerComponent(IPMPage, {
  name: 'IPM Page',
  inputs: [
    {
      name: 'initialContainerWidth',
      type: 'number',
      defaultValue: 800,
    },
  ],
})

Builder.registerComponent(IPMGrid, {
  name: 'IPMGrid',
  inputs: [
    {
      name: 'products',
      type: 'list',
      subFields: [
        { name: 'id', type: 'number' },
        { name: 'name', type: 'string' },
        { name: 'price', type: 'number' },
        { name: 'image', type: 'string' }
      ],
      defaultValue: [
        {
          id: 1,
          name: 'T-250 System',
          price: 3459.00,
          image: '/placeholder.svg?height=200&width=200'
        },
        // ... you can add more default products here
      ]
    }
  ]
})


Builder.registerComponent(IPMGrid, {
  name: 'IPM Grid',
  inputs: [
    {
      name: 'products',
      type: 'list',
      defaultValue: [
        {
          id: 1,
          name: 'Neutra-Control A',
          sub: '(Preventative) 42Floz',
          price: 129.00,
          image: '/products/IPMSolutions/Neutrasolution.png',
          url: '/neutracontrol-a'

        },
        // ... you can add more default products here
      ],
      subFields: [
        { name: 'id', type: 'number' },
        { name: 'name', type: 'string' },
        { name: 'sub', type: 'string' },
        { name: 'price', type: 'number' },
        { name: 'image', type: 'string' },
        { name: 'url', type: 'string' }

      ]
    },
    {
      name: 'onAddToCart',
      type: 'function',
      defaultValue: '(productId) => { console.log(`Product ${productId} added to cart`); }',
    }
  ],
})

Builder.registerComponent(Form, {
  name: 'Form',
  inputs: [
    {
      name: 'isDarkMode',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'fields',
      type: 'array',
      subFields: [
        {
          name: 'name',
          type: 'string',
          required: true,
        },
        {
          name: 'type',
          type: 'string',
          enum: ['text', 'textarea', 'email', 'number', 'checkbox'],
          defaultValue: 'text',
        },
        {
          name: 'label',
          type: 'string',
        },
        {
          name: 'placeholder',
          type: 'string',
        },
        {
          name: 'required',
          type: 'boolean',
          defaultValue: false,
        },
      ],
    },
    {
      name: 'submitText',
      type: 'string',
      defaultValue: 'Submit',
    },
    {
      name: 'successMessage',
      type: 'string',
      defaultValue: 'Form submitted successfully!',
    },
  ],
})






// Builder.registerComponent(FormG, {
//   name: 'FormG',
//   inputs: [
//     {
//       name: 'isDarkMode',
//       type: 'boolean',
//       defaultValue: false
//     },
//     {
//       name: 'fields',
//       type: 'array',
//       subFields: [
//         {
//           name: 'name',
//           type: 'string',
//           required: true,
//         },
//         {
//           name: 'type',
//           type: 'string',
//           enum: ['text', 'textarea', 'email', 'number', 'checkbox'],
//           defaultValue: 'text',
//         },
//         {
//           name: 'label',
//           type: 'string',
//         },
//         {
//           name: 'placeholder',
//           type: 'string',
//         },
//         {
//           name: 'required',
//           type: 'boolean',
//           defaultValue: false,
//         },
//       ],
//     },
//     {
//       name: 'submitText',
//       type: 'string',
//       defaultValue: 'Submit',
//     },
//     {
//       name: 'successMessage',
//       type: 'string',
//       defaultValue: 'Form submitted successfully!',
//     },
//   ],
// })


Builder.registerComponent(FormG, {
  name: 'FormG',
  inputs: [
    {
      name: 'isDarkMode',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'fields',
      type: 'array',
      subFields: [
        {
          name: 'name',
          type: 'string',
          required: true,
        },
        {
          name: 'type',
          type: 'string',
          enum: ['text', 'textarea', 'email', 'number', 'checkbox'],
          defaultValue: 'text',
        },
        {
          name: 'label',
          type: 'string',
        },
        {
          name: 'placeholder',
          type: 'string',
        },
        {
          name: 'required',
          type: 'boolean',
          defaultValue: false,
        },
      ],
    },
    {
      name: 'submitText',
      type: 'string',
      defaultValue: 'Submit',
    },
    {
      name: 'successMessage',
      type: 'string',
      defaultValue: 'Form submitted successfully!',
    },
  ],
})

// Builder.registerComponent(FormG, {
//   name: 'FormG',
//   inputs: [
//     {
//       name: 'isDarkMode',
//       type: 'boolean',
//       defaultValue: false
//     },
//     {
//       name: 'fields',
//       type: 'array',
//       subFields: [
//         {
//           name: 'name',
//           type: 'string',
//           required: true,
//         },
//         {
//           name: 'type',
//           type: 'string',
//           enum: ['text', 'textarea', 'email', 'number', 'checkbox'],
//           defaultValue: 'text',
//         },
//         {
//           name: 'label',
//           type: 'string',
//         },
//         {
//           name: 'placeholder',
//           type: 'string',
//         },
//         {
//           name: 'required',
//           type: 'boolean',
//           defaultValue: false,
//         },
//       ],
//     },
//     {
//       name: 'submitText',
//       type: 'string',
//       defaultValue: 'Submit',
//     },
//     {
//       name: 'successMessage',
//       type: 'string',
//       defaultValue: 'Form submitted successfully!',
//     },
//   ],
// })

Builder.registerComponent(FormG2, {
  name: 'FormG2',
  inputs: [
    {
      name: 'isDarkMode',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'fields',
      type: 'array',
      subFields: [
        {
          name: 'name',
          type: 'string',
          required: true,
        },
        {
          name: 'type',
          type: 'string',
          enum: ['text', 'textarea', 'email', 'number', 'checkbox'],
          defaultValue: 'text',
        },
        {
          name: 'label',
          type: 'string',
        },
        {
          name: 'placeholder',
          type: 'string',
        },
        {
          name: 'required',
          type: 'boolean',
          defaultValue: false,
        },
      ],
    },
    {
      name: 'submitText',
      type: 'string',
      defaultValue: 'Submit',
    },
    {
      name: 'successMessage',
      type: 'string',
      defaultValue: 'Form submitted successfully!',
    },
  ],
})

Builder.registerComponent(ProfileCards, {
  name: "ProfileCards",
  inputs: [
    {
      name: "profiles",
      type: "list",
      subFields: [
        {
          name: "name",
          type: "string",
          required: true,
        },
        {
          name: "title",
          type: "string",
          required: true,
        },
      ],
      defaultValue: [
        { name: 'Adrian', title: 'CHIEF OPERATING OFFICER' },
        { name: 'Mike', title: 'EXPERT FOGGER' },
        { name: 'Sarah', title: 'HEAD OF MARKETING' },
        { name: 'John', title: 'LEAD DEVELOPER' },
        { name: 'Emily', title: 'CUSTOMER SUCCESS MANAGER' },
        { name: 'David', title: 'FINANCIAL ANALYST' },
      ],
    },
  ],
});


Builder.registerComponent(ProfileCardsC, {
  name: "ProfileCardsC",
  inputs: [
    {
      name: "profiles",
      type: "list",
      subFields: [
        {
          name: "name",
          type: "string",
          required: true,
        },
        {
          name: "title",
          type: "string",
          required: true,
        },
        {
          name: "bio",
          type: "longText",
          required: true,
        },
        {
          name: "image",
          type: "file",
          allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
          required: false,
        },
      ],
      defaultValue: [
        {
          name: "Adrian",
          title: "CHIEF OPERATING OFFICER",
          bio: "Adrian brings over 15 years of operational excellence to our team. His strategic vision drives our company's growth and efficiency.",
          image: "/adrian.png", // Add a default image URL if you have one, or leave empty
        },
        {
          name: "Mike",
          title: "EXPERT FOGGER",
          bio: "With an uncanny ability to navigate through complex situations, Mike ensures our projects stay on track even in the haziest conditions.",
          image: "", // Add a default image URL if you have one, or leave empty
        },
      ],
    },
  ],
});

Builder.registerComponent(CallButton, {
  name: "CallButton",
  inputs: [
    {
      name: "modalTitle",
      type: "string",
      defaultValue: "Get In Touch",
    },
    {
      name: "modalDescription",
      type: "string",
      defaultValue: "Fill out this form and we'll get back to you as soon as possible.",
    },
    {
      name: "submitButtonText",
      type: "string",
      defaultValue: "Submit",
    },
    {
      name: "showLocationField",
      type: "boolean",
      defaultValue: true,
    },
  ],
});

Builder.registerComponent(ProductPages, {
  name: "ProductPages",
  inputs: [
    // Define inputs if your ProductPage component needs any props
  ],
});

Builder.registerComponent(ProductPageMobile, {
  name: "ProductPageMobile",
  inputs: [
    // Define inputs if your ProductPageMobile component needs any props
  ],
});



Builder.registerComponent(ProductPages, {
  name: 'ProductPages',
  inputs: [
    {
      name: 'productImages',
      type: 'list',
      subFields: [{ name: 'image', type: 'file' }],
    },
    {
      name: 'productName',
      type: 'string',
    },
    {
      name: 'productPrice',
      type: 'string',
    },
    {
      name: 'productDescription',
      type: 'string',
    },
    {
      name: 'productId',
      type: 'string',
    },
    {
      name: 'features',
      type: 'list',
      subFields: [
        { name: 'title', type: 'string' },
        { name: 'description', type: 'string' },
      ],
    },
    {
      name: 'accordionData',
      type: 'list',
      subFields: [
        { name: 'title', type: 'string' },
        { name: 'content', type: 'string' },
      ],
    },
  ],
});



Builder.registerComponent(IPMPages, {
  name: 'IPMDetails',
  inputs: [
    {
      name: 'productImages',
      type: 'list',
      subFields: [{ name: 'image', type: 'file' }],
    },
    {
      name: 'productName',
      type: 'string',
    },
    {
      name: 'productPrice',
      type: 'string',
    },
    {
      name: 'productDescription',
      type: 'string',
    },
    {
      name: 'productId',
      type: 'string',
    },
    {
      name: 'features',
      type: 'list',
      subFields: [
        { name: 'title', type: 'string' },
        { name: 'description', type: 'string' },
      ],
    },
    {
      name: 'accordionData',
      type: 'list',
      subFields: [
        { name: 'title', type: 'string' },
        { name: 'content', type: 'string' },
      ],
    },
  ],
});



Builder.registerComponent(SolutionWorks, {
  name: 'SolutionWorks',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'This Solution Works In Multiple Ways.',
    },
    {
      name: 'description',
      type: 'string',
      defaultValue: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus at sodales sodales. Quisque sagittis orci ut diam condimentum, vel euismod erat placerat.',
    },
    {
      name: 'bulletPoints',
      type: 'list',
      subFields: [
        { name: 'point', type: 'string' },
      ],
      defaultValue: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla.',
        'Mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu.',
        'Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus at sodales sodales.',
        'Quisque sagittis orci ut diam condimentum, vel euismod erat placerat.',
      ],
    },
    {
      name: 'additionalDescription',
      type: 'string',
      defaultValue: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus at sodales sodales. Quisque sagittis orci ut diam condimentum, vel euismod erat placerat.',
    },
  ],
});






Builder.registerComponent(IPMPages, {
  name: 'IPMPages',
  inputs: [
    {
      name: 'productImages',
      type: 'list',
      subFields: [{ name: 'image', type: 'file' }],
    },
    {
      name: 'productName',
      type: 'string',
    },
    {
      name: 'productPrice',
      type: 'string',
    },
    {
      name: 'productDescription',
      type: 'string',
    },
    {
      name: 'productId',
      type: 'string',
    },
    {
      name: 'features',
      type: 'list',
      subFields: [
        { name: 'title', type: 'string' },
        { name: 'description', type: 'string' },
      ],
    },
    {
      name: 'accordionData',
      type: 'list',
      subFields: [
        { name: 'title', type: 'string' },
        { name: 'content', type: 'string' },
      ],
    },
    {
      name: 'solutionWorksTitle',
      type: 'string',
      defaultValue: 'This Solution Works In Multiple Ways.',
    },
    {
      name: 'solutionWorksDescription',
      type: 'string',
      defaultValue: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus at sodales sodales. Quisque sagittis orci ut diam condimentum, vel euismod erat placerat.',
    },
    {
      name: 'solutionWorksBulletPoints',
      type: 'list',
      subFields: [
        { name: 'point', type: 'string' },
      ],
      defaultValue: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla.',
        'Mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu.',
        'Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus at sodales sodales.',
        'Quisque sagittis orci ut diam condimentum, vel euismod erat placerat.',
      ],
    },
    {
      name: 'solutionWorksAdditionalDescription',
      type: 'string',
      defaultValue: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus at sodales sodales. Quisque sagittis orci ut diam condimentum, vel euismod erat placerat.',
    },
  ],
});


Builder.registerComponent(Component, {
  name: 'NewResource',
  inputs: [
    {
      name: 'activeSection',
      type: 'string',
      enum: ['Product SOPs', 'Instructional Videos', 'FAQs', 'News & Blog'],
      defaultValue: 'FAQs',
    },
    {
      name: 'resources',
      type: 'object',
      subFields: [
        {
          name: 'Product SOPs',
          type: 'object',
          subFields: [
            { name: 'title', type: 'string', defaultValue: 'Product SOPs' },
            { name: 'icon', type: 'ReactNode' },
          ],
        },
        {
          name: 'Instructional Videos',
          type: 'object',
          subFields: [
            { name: 'title', type: 'string', defaultValue: 'Instructional Videos' },
            { name: 'icon', type: 'ReactNode' },
          ],
        },
        {
          name: 'FAQs',
          type: 'object',
          subFields: [
            { name: 'title', type: 'string', defaultValue: 'FAQs' },
            { name: 'icon', type: 'ReactNode' },
          ],
        },
        {
          name: 'News & Blog',
          type: 'object',
          subFields: [
            { name: 'title', type: 'string', defaultValue: 'News & Blog' },
            { name: 'icon', type: 'ReactNode' },
          ],
        },
      ],
    },
  ],
});




Builder.registerComponent(CallButtonMobile, {
  name: 'Call Button Mobile',
  inputs: [],
});


Builder.registerComponent(Faqq, {
  name: 'FAQ Section',
  inputs: [
    { 
      name: 'title', 
      type: 'string', 
      defaultValue: 'Frequently Asked Questions'
    },
    { 
      name: 'description', 
      type: 'longText'
    },
    {
      name: 'faqItems',
      type: 'list',
      subFields: [
        { name: 'question', type: 'string' },
        { name: 'answer', type: 'longText' }
      ]
    },
    { 
      name: 'backgroundColor', 
      type: 'color', 
      defaultValue: '#ffffff'
    },
    { 
      name: 'textColor', 
      type: 'color', 
      defaultValue: '#000000'
    },
    {
      name: 'faqTitleColor',
      type: 'color',
      defaultValue: '#0b0b0b'
    },
    {
      name: 'faqContentColor',
      type: 'color',
      defaultValue: '#0b0b0b'
    }
  ],
})


Builder.registerComponent(ProfileCards, {
  name: "ProfileCards",
  inputs: [
    {
      name: "profiles",
      type: "list",
      subFields: [
        {
          name: "name",
          type: "string",
          required: true,
        },
        {
          name: "title",
          type: "string",
          required: true,
        },
        {
          name: "description",
          type: "string",
          required: false,
        },
        {
          name: "imageSrc",
          type: "file",
          allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
          required: false,
        },
      ],
      defaultValue: [
        { name: 'Adrian', title: 'CHIEF OPERATING OFFICER', description: 'Adrian brings over 15 years of operational excellence to our team.', imageSrc: '/adrian.png' },
        { name: 'Mike', title: 'EXPERT FOGGER', description: 'Mike ensures our projects stay on track even in the haziest conditions.', imageSrc: '/mike.png' },
      ],
    },
  ],
});



Builder.registerComponent(ProfileCards, {
  name: 'ProfileCards',
  inputs: [
    {
      name: 'profiles',
      type: 'list',
      subFields: [
        {
          name: 'name',
          type: 'string',
          required: true,
        },
        {
          name: 'title',
          type: 'string',
          required: true,
        },
        {
          name: 'description',
          type: 'string',
          required: false,
        },
        {
          name: 'imageSrc',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
        },
        {
          name: 'linkedinUrl',
          type: 'string',
          required: false,
        },
        {
          name: 'websiteUrl',
          type: 'string',
          required: false,
        },
      ],
      defaultValue: [
        {
          name: 'Adrian',
          title: 'CHIEF OPERATING OFFICER',
          description: 'Adrian brings over 15 years of operational excellence to our team.',
          imageSrc: '/adrian.png',
          linkedinUrl: 'https://www.linkedin.com/in/adrian',
          websiteUrl: 'https://www.adrian.com',
        },
        {
          name: 'Mike',
          title: 'EXPERT FOGGER',
          description: 'Mike ensures our projects stay on track even in the haziest conditions.',
          imageSrc: '/mike.png',
          linkedinUrl: 'https://www.linkedin.com/in/mike',
          websiteUrl: 'https://www.mike.com',
        },
      ],
    },
  ],
});

// Builder.registerComponent(Faqq, {
//   name: 'FAQ Section',
//   inputs: [
//     { 
//       name: 'title', 
//       type: 'string', 
//       defaultValue: 'Frequently Asked Questions'
//     },
//     { 
//       name: 'description', 
//       type: 'longText'
//     },
//     {
//       name: 'faqItems',
//       type: 'list',
//       subFields: [
//         { name: 'question', type: 'string' },
//         { name: 'answer', type: 'longText' }
//       ]
//     },
//     { 
//       name: 'backgroundColor', 
//       type: 'color', 
//       defaultValue: '#ffffff'
//     },
//     { 
//       name: 'textColor', 
//       type: 'color', 
//       defaultValue: '#000000'
//     },
//     {
//       name: 'faqTitleFontFamily',
//       type: 'string',
//       defaultValue: 'Arial, sans-serif'
//     },
//     {
//       name: 'faqTitleFontSize',
//       type: 'number',
//       defaultValue: 16
//     },
//     {
//       name: 'faqContentFontFamily',
//       type: 'string',
//       defaultValue: 'Arial, sans-serif'
//     },
//     {
//       name: 'faqContentFontSize',
//       type: 'number',
//       defaultValue: 14
//     }
//   ],
// })


// Builder.registerComponent(Faqq, {
//   name: 'FAQ Section',
//   inputs: [
//     { 
//       name: 'title', 
//       type: 'string', 
//       defaultValue: 'Frequently Asked Questions'
//     },
//     { 
//       name: 'description', 
//       type: 'longText'
//     },
//     {
//       name: 'faqItems',
//       type: 'list',
//       subFields: [
//         { name: 'question', type: 'string' },
//         { name: 'answer', type: 'longText' }
//       ]
//     },
//     { 
//       name: 'backgroundColor', 
//       type: 'color', 
//       defaultValue: '#ffffff'
//     },
//     { 
//       name: 'textColor', 
//       type: 'color', 
//       defaultValue: '#000000'
//     },
//     {
//       name: 'faqTitleFontFamily',
//       type: 'string',
//       defaultValue: 'Arial, sans-serif'
//     },
//     {
//       name: 'faqTitleFontSize',
//       type: 'number',
//       defaultValue: 16
//     },
//     {
//       name: 'faqContentFontFamily',
//       type: 'string',
//       defaultValue: 'Arial, sans-serif'
//     },
//     {
//       name: 'faqContentFontSize',
//       type: 'number',
//       defaultValue: 14
//     }
//   ],
// })



// Builder.registerComponent(Faqq, {
//   name: 'FAQ Section',
//   inputs: [
//     { 
//       name: 'title', 
//       type: 'string', 
//       defaultValue: 'Frequently Asked Questions'
//     },
//     { 
//       name: 'description', 
//       type: 'longText'
//     },
//     {
//       name: 'faqItems',
//       type: 'list',
//       subFields: [
//         { name: 'question', type: 'string' },
//         { name: 'answer', type: 'longText' }
//       ]
//     },
//     { 
//       name: 'backgroundColor', 
//       type: 'color', 
//       defaultValue: '#ffffff'
//     },
//     { 
//       name: 'textColor', 
//       type: 'color', 
//       defaultValue: '#000000'
//     }
//   ],
// })

// // Register the component with Builder.io
// Builder.registerComponent(Sop__, {
//   name: 'SOP__',
//   inputs: [
//     {
//       name: 'selectedTableItem',
//       type: 'object',
//       defaultValue: null,
//     },
//     {
//       name: 'selectedDropdownItem',
//       type: 'string',
//       defaultValue: null,
//     },
//     {
//       name: 'searchQuery',
//       type: 'string',
//       defaultValue: '',
//     },
//     {
//       name: 'submittedData',
//       type: 'object',
//       defaultValue: null,
//     },
//     {
//       name: 'selectedArea',
//       type: 'string',
//       defaultValue: '25000',
//     },
//   ],
// });



// Builder.registerComponent(LazyProductPages, {
//   name: 'ProductPages',
//   inputs: [
//     {
//       name: 'productImages',
//       type: 'list',
//       subFields: [{ name: 'image', type: 'file' }],
//     },
//     {
//       name: 'productName',
//       type: 'string',
//     },
//     {
//       name: 'productPrice',
//       type: 'string',
//     },
//     {
//       name: 'productDescription',
//       type: 'string',
//     },
//     {
//       name: 'productId',
//       type: 'string',
//     },
//     {
//       name: 'features',
//       type: 'list',
//       subFields: [
//         { name: 'title', type: 'string' },
//         { name: 'description', type: 'string' },
//       ],
//     },
//     {
//       name: 'accordionData',
//       type: 'list',
//       subFields: [
//         { name: 'title', type: 'string' },
//         { name: 'content', type: 'string' },
//       ],
//     },
//   ],
// });