const dummyData = {
  tableData: [
      { id: 1, name: "Azagaurd (every 7 days until pressure subsides or stop by day 18 flower)" },
      { id: 2, name: "Azagaurd (every 5 days until pressure subsides or stop by day 18 flower)" },
      { id: 3, name: "Azamax (every 7 days until pressure subsides or stop by day 18)" },
      { id: 4, name: "Azamax (every 5 days until pressure subsides or stop by day 18)" },
      { id: 5, name: "Azatin (every 7 days until pressure subsides or stop by day 18)" },
      { id: 6, name: "Azatin (every 5 days until pressure subsides or stop by day 18)" },
      { id: 7, name: "Suffoil (every 5 days till pressure subsides or stop use by day 18 of flower) Pest dependent" },
      { id: 8, name: "Suffoil (every 3 days till pressure subsides or stop use by day 18 of flower) Pest dependent" },
      { id: 9, name: "Tritek (every 5 days till pressure subsides or stop use by day 18 of flower) Pest dependent" },
      { id: 10, name: "Tritek (every 3 days till pressure subsides or stop use by day 18 of flower) Pest dependent" },
      { id: 11, name: "Big Time Exterminator (every 5 days till pressure subsides)" },
      { id: 12, name: "Big Time Exterminator (every 3 days till pressure subsides)" },
      { id: 13, name: "Smite (every 7 days until pressure subsides, can be used in late flower)" },
      { id: 14, name: "Smite (every 5 days until pressure subsides, can be used in late flower)" },
      { id: 15, name: "SNS 203 (every 3 days, stop by day 18 of flower, no more than 5 applications per crop cycle)" },
      { id: 16, name: "SNS 203 (every 2 days, stop by day 18 of flower, no more than 5 applications per crop cycle)" },
      { id: 17, name: "Mammoth Biocontrol (every 5 days till pressure subsides, can be used in late flower)" },
      { id: 18, name: "Mammoth Biocontrol (every 3 days till pressure subsides, can be used in late flower)" },
      { id: 19, name: "Mammoth CannControl (every 5 days till pressure subsides, can be used in late flower)" },
      { id: 20, name: "Mammoth CannControl (every 3 days till pressure subsides, can be used in late flower)" },
      { id: 21, name: "Athena IPM (apply daily with a day break every 3 days) any soft body insects)" },
      { id: 22, name: "Athena IPM (apply daily with a day break every 5 days) any soft body insects)" },
      { id: 23, name: "Growsafe (every 5 days then stop by day 18 of flower)" },
      { id: 24, name: "Growsafe (every 3 days then stop by day 18 of flower)" },
      { id: 25, name: "Wizards Brew Concentrate (every 5 days then stop by day 18 of flower)" },
      { id: 26, name: "Wizards Brew Concentrate (every 3 days then stop by day 18 of flower)" },
      { id: 27, name: "TetraCurb (every 5 days and can be used in late flower)" },
      { id: 28, name: "TetraCurb (every 3 days and can be used in late flower)" },
      { id: 29, name: "Organishield (every 10 days no more than 6 applications per crop cycle)" },
      { id: 30, name: "Organishield (every 5 days no more than 6 applications per crop cycle)" },
      { id: 31, name: "Plant Therapy (every 5 days can be used in late flower, use as many times in a cycle until you see signs of damaged plants)" },
      { id: 32, name: "Plant Therapy (every 3 days can be used in late flower, use as many times in a cycle until you see signs of damaged plants)" },
      { id: 33, name: "Green Cleaner (every 5 days can be used in late flower, use as many times in a cycle until you see signs of damaged plants)" },
      { id: 34, name: "Green Cleaner (every 3 days can be used in late flower, use as many times in a cycle until you see signs of damaged plants)" },
      { id: 35, name: "Dr. Zymes (every 5 days can be used in late flower)" },
      { id: 36, name: "Dr. Zymes (every 3 days can be used in late flower)" },
      { id: 37, name: "Flying Skull Nuke Em (every 3 days can be used in late flower)" },
      { id: 38, name: "Flying Skull Nuke Em (every 2 days can be used in late flower)" },
      { id: 39, name: "Dr. Bronners Peppermint (every 5 days can be used in late flower)" },
      { id: 40, name: "Dr. Bronners Peppermint (every 3 days can be used in late flower)" },
      { id: 41, name: "Mpede (every 5 days can be used in late flower)" },
      { id: 42, name: "Mpede (every 3 days can be used in late flower)" },
      { id: 43, name: "Pure Crop1 (every 3 days can be used up to day of harvest)" },
    { id: 44, name: "Pure Crop1 (every 5 days can be used up to day of harvest)" },
    { id: 45, name: "Pyganic5.0 or Specialty (can be used every 5 days under pressure and can be used every 3 days under extreme pressure, stop if signs of damaged to plant, greenhouses do not apply more than 10 times per crop cycle indoor no more than 4 times) DO NOT USE IN FLOWER PHASE SHOULD ONLY BE USED IN VEGETATIVE PHASE" },
    { id: 46, name: "Captain Jacks w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)" },
    { id: 47, name: "Captain Jacks w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)" },
    { id: 48, name: "Monterey w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)" },
    { id: 49, name: "Monterey w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)" },
    { id: 50, name: "Circadian Sunrise (every 3 days can be used in late flower)" },
    { id: 51, name: "Circadian Sunrise (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)" },
    { id: 52, name: "Circadian Sunrise + Allphase (every 3 days can be used in late flower)" },
    { id: 53, name: "Circadian Sunrise + Allphase (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)" },
    { id: 54, name: "Azagaurd Oxiphos Zerotol (every 7 days under pressure, stop by day 21 of flower)" },
    { id: 55, name: "Azagaurd Oxiphos Zerotol Pro (every 7 days under pressure, stop by day 21 of flower)" },
    { id: 56, name: "Netra Fog HOCL (can be used daily, stop using at first sign of damage to plants)" },
    { id: 57, name: "Regalia Maxx (every 7 days under pressure can be used in late flower but recommended to stop using in day 21)" },
    { id: 58, name: "Regalia Maxx (every 5 days under pressure can be used in late flower but recommended to stop using in day 20)" },
    { id: 59, name: "Procidic2 (every 5 days. can be used up to day of harvest)" },
    { id: 60, name: "Procidic2 (every 3 days. can be used up to day of harvest)" },
    { id: 61, name: "Zerotol 2.0 (every 3 days under pressure can be used up to the day of harvest)" },
    { id: 62, name: "Zerotol 2.0 (every 2 days under pressure can be used up to the day of harvest)" },
    { id: 63, name: "Oxiphos (every 7 days under pressure stop using by day 14 of flower)" },
    { id: 64, name: "Dr Zymes (every 3 days under pressure, can be used in late flower)" },
    { id: 65, name: "Dr Zymes (high pressure apply 3 days consecutively then stop and apply 7 days later. after 3 days repeat pattern until pressure subsides, can be used in late flower)" },
    { id: 66, name: "Flying Skull Nuke em (every 3 days, can be used up to day of harvest)" },
    { id: 67, name: "Flying Skull Nuke em (every 2 days, can be used up to day of harvest)" },
    { id: 68, name: "Wizards Brew Concentrate (every 5 days under pressure can be used in late flower)" },
    { id: 69, name: "Pure Crop1 (every 5 days can be used up to day of harvest)" },
    { id: 70, name: "Pure Crop1 (every 3 days can be used up to day of harvest)" },
    { id: 71, name: "Circadian Sunrise + Allphase (every 5 days can be used in late flower)" },
    { id: 72, name: "Circadian Sunrise + Allphase (every 3 days can be used in late flower)" },
    { id: 73, name: "Allphase (every 2 days or as needed can be used in late flower)" },
    { id: 74, name: "Grandevo CG (2 times per week can be used in late flower) Aura to be used as wetting agent/carrier not Halo" },
    { id: 75, name: "Grandevo WDG (2 times per week can be used in late flower) Aura to be used as wetting agent/carrier not Halo" },
    { id: 76, name: "Venerate CG (2 times per week can be used in late flower) Aura to be used as wetting agent/carrier not Halo" },
    { id: 77, name: "Botanigaurd 22wp (every 7 days can be used in late flower) Aura to be used as wetting agent/carrier not Halo" },
    { id: 78, name: "Botanigaurd Maxx (should only be used in vegetative phase no more than 6 times per crop cycle with interval of 7 days) Aura to be used as wetting agent/carrier not Halo" },
    { id: 79, name: "Power si Original (1 to 2 times per week depending on the health of the plant)" },
    { id: 80, name: "Power si Bloom (1 to 2 times per week depending on the health of the plant)" },
    { id: 81, name: "Athena Stack (1 to 2 times per week depending on the health of the plant)" },
    { id: 82, name: "Europonic Nitrozime (1 to 2 times per week depending on the health of the plant)" },
    { id: 83, name: "CES Foliar (1 to 2 times per week depending on the health of the plant)" },
    { id: 84, name: "B1 Thrive Alive Green & Red (1 to 2 times per week depending on the health of the plant)" },
    { id: 85, name: "Humboldt Humic (1 to 2 times per week depending on the health of the plant)" },
    { id: 86, name: "House n Garden Magic Green (1 to 2 times per week depending on the health of the plant)" },
    { id: 87, name: "Optic Foliar Transport + Rev + Watts (1 to 2 times per week depending on the health of the plant)" },
    { id: 88, name: "Neutra Fog HOCL 2 times per week" },
    { id: 89, name: "Sanidate5.0 2 times per week" },
    { id: 90, name: "Athena Cleanse 2 times per week" },
  ],
  dropdownOptions: [
    { value: "t250", label: "T-250" },
    { value: "t500", label: "T-500" },
    { value: "pro1500", label: "Pro-1500" },
    { value: "pro4000", label: "Pro-4000" },

  ],
};

interface ResultData {
  title: string;
  sizeArea: string[];
  mixture: { name: string; value: string }[];
  preventativeDays: number;
  reactiveDays: number;
}

const resultDataMap: Record<string, Record<string, Record<string, ResultData>>> = {
  "t250": {

    "1": {
      "25000": {
        title: "T-250, using Azagaurd (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1215ml" },
          { name: "Azagaurd", value: "15ml" },
          { name: "H2O", value: "20ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Azagaurd (minimum) (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1190ml" },
          { name: "Azagaurd", value: "30ml" },
          { name: "H2O", value: "30ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "2": {
      "25000": {
        title: "T-250, using Azagaurd (maximum) (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1160ml" },
          { name: "Azagaurd", value: "36ml" },
          { name: "H2O", value: "54ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
      "50000": {
        title: "T-250, using Azagaurd (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1110ml" },
          { name: "Azagaurd", value: "75ml" },
          { name: "H2O", value: "65ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
    },
    "3": {
      "25000": {
        title: "T-250, using Azamax (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1190ml" },
          { name: "Azamax", value: "45ml" },
          { name: "H2O", value: "25ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      // not recommended
      "50000": {
        title: "T-250, using Azamax (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1190ml" },
          { name: "Azamax", value: "45ml" },
          { name: "H2O", value: "25ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "4": {
      "25000": {
        title: "T-250, using Azamax (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1090ml" },
          { name: "Azamax", value: "120ml" },
          { name: "H2O", value: "40ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
      //not recommended past 25,000
      "50000": {
        title: "T-250, using Azamax (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1090ml" },
          { name: "Azamax", value: "120ml" },
          { name: "H2O", value: "40ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
    },
    "5": {
      "25000": {
        title: "T-250, using Azatin (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1215ml" },
          { name: "Azatin", value: "15ml" },
          { name: "H2O", value: "20ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Azatin (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1200ml" },
          { name: "Azatin", value: "30ml" },
          { name: "H2O", value: "20ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "6": {
      "25000": {
        title: "T-250, using Azatin (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1190ml" },
          { name: "Azatin", value: "36ml" },
          { name: "H2O", value: "24ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
      "50000": {
        title: "T-250, using Azatin (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Azatin", value: "75ml" },
          { name: "H2O", value: "25ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
    },
    "7": {
      "25000": {
        title: "T-250, using Suffoil (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Suffoil-x", value: "75ml" },
          { name: "ISO", value: "25ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      // not recommended
      "50000": {
        title: "T-250, using Suffoil (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Suffoil-x", value: "75ml" },
          { name: "ISO", value: "25ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "8": {
      "25000": {
        title: "T-250, using Suffoil (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Suffoil-x", value: "300ml" },
          { name: "ISO", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      //not recommended
      "50000": {
        title: "T-250, using Suffoil (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Suffoil-x", value: "300ml" },
          { name: "ISO", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "9": {
      "25000": {
        title: "T-250, using Tritek (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Tri-Tek", value: "50ml" },
          { name: "ISO", value: "25ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Tritek (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Tri-Tek", value: "50ml" },
          { name: "ISO", value: "25ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "10": {
      "25000": {
        title: "T-250, using Tritek (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Tri-Tek", value: "300ml" },
          { name: "ISO", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Tritek (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Tri-Tek", value: "300ml" },
          { name: "ISO", value: "175ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "11": {
      "25000": {
        title: "T-250, using Big Time Exterminator (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Big Time", value: "90ml" },
          { name: "ISO", value: "10ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Big Time Exterminator (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Big Time", value: "90ml" },
          { name: "ISO", value: "10ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      // for larger devices


      // "50000": {
      //   title: "T-250, using Big Time Exterminator (every 5 days)",
      //   sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      //   mixture: [
      //     { name: "Halo", value: "3100ml" },
      //     { name: "Big Time", value: "400ml" },
      //     { name: "ISO", value: "100ml" },
      //     { name: "PH", value: "5.8-6.2" },
      //   ],
      //   preventativeDays: 5,
      //   reactiveDays: 0,
      // },
    },
    "12": {
      "25000": {
        title: "T-250, using Big Time Exterminator (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Big Time", value: "300ml" },
          { name: "ISO", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Big Time Exterminator (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Big Time", value: "300ml" },
          { name: "ISO", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },

      // for larger
    //   "50000": {
    //     title: "T-250, using Big Time Exterminator (every 3 days)",
    //     sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
    //     mixture: [
    //       { name: "Halo", value: "2550ml" },
    //       { name: "Big Time", value: "950ml" },
    //       { name: "ISO", value: "100ml" },
    //       { name: "PH", value: "5.8-6.2" },
    //     ],
    //     preventativeDays: 3,
    //     reactiveDays: 3,
    //   },
    // },
    "13": {
      "25000": {
        title: "T-250, using Smite (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Smite", value: "60ml" },
          { name: "ISO", value: "40ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Smite (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Smite", value: "60ml" },
          { name: "ISO", value: "40ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      // "50000": {
      //   title: "T-250, using Smite (every 7 days)",
      //   sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      //   mixture: [
      //     { name: "Halo", value: "3200ml" },
      //     { name: "Smite", value: "300ml" },
      //     { name: "ISO", value: "100ml" },
      //     { name: "PH", value: "5.8-6.2" },
      //   ],
      //   preventativeDays: 7,
      //   reactiveDays: 0,
      // },
    },
    "14": {
      "25000": {
        title: "T-250, using Smite (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Smite", value: "300ml" },
          { name: "ISO", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
      "50000": {
        title: "T-250, using Smite (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Smite", value: "300ml" },
          { name: "ISO", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
      // "50000": {
      //   title: "T-250, using Smite (every 5 days)",
      //   sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      //   mixture: [
      //     { name: "Halo", value: "2550ml" },
      //     { name: "Smite", value: "950ml" },
      //     { name: "ISO", value: "100ml" },
      //     { name: "PH", value: "5.8-6.2" },
      //   ],
      //   preventativeDays: 5,
      //   reactiveDays: 5,
      // },
    },
    "15": {
      "25000": {
        title: "T-250, using SNS 203 (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1050ml" },
          { name: "SNS 203", value: "120ml" },
          { name: "ISO", value: "80ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using SNS 203 (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1050ml" },
          { name: "SNS 203", value: "120ml" },
          { name: "ISO", value: "80ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      // "50000": {
      //   title: "T-250, using SNS 203 (every 3 days)",
      //   sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      //   mixture: [
      //     { name: "Halo", value: "3100ml" },
      //     { name: "SNS 203", value: "400ml" },
      //     { name: "ISO", value: "400ml" },
      //     { name: "PH", value: "5.8-6.2" },
      //   ],
      //   preventativeDays: 3,
      //   reactiveDays: 3,
      // },
    },
    "16": {
      "25000": {
        title: "T-250, using SNS 203 (every 2 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "950ml" },
          { name: "SNS 203", value: "100ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 2,
        reactiveDays: 2,
      },
      "50000": {
        title: "T-250, using SNS 203 (every 2 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "950ml" },
          { name: "SNS 203", value: "100ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 2,
        reactiveDays: 2,
      },
      // "50000": {
      //   title: "T-250, using SNS 203 (every 2 days)",
      //   sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      //   mixture: [
      //     { name: "Halo", value: "2550ml" },
      //     { name: "SNS 203", value: "950ml" },
      //     { name: "ISO", value: "100ml" },
      //     { name: "PH", value: "5.8-6.2" },
      //   ],
      //   preventativeDays: 2,
      //   reactiveDays: 2,
      // },
    },
    "17": {
      "25000": {
        title: "T-250, using Mammoth Biocontrol (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "BioControl", value: "60ml" },
          { name: "R/O ISO", value: "40ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },

      "50000": {
        title: "T-250, using Mammoth Biocontrol (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "BioControl", value: "60ml" },
          { name: "R/O ISO", value: "40ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      // "50000": {
      //   title: "T-250, using Mammoth Biocontrol (every 5 days)",
      //   sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      //   mixture: [
      //     { name: "Halo", value: "3200ml" },
      //     { name: "BioControl", value: "300ml" },
      //     { name: "R/O ISO", value: "100ml" },
      //     { name: "PH", value: "5.8-6.2" },
      //   ],
      //   preventativeDays: 5,
      //   reactiveDays: 0,
      // },
    },
    "18": {
      "25000": {
        title: "T-250, using Mammoth Biocontrol (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "BioControl", value: "300ml" },
          { name: "ISO", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Mammoth Biocontrol (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "BioControl", value: "300ml" },
          { name: "ISO", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      // "50000": {
      //   title: "T-250, using Mammoth Biocontrol (every 3 days)",
      //   sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      //   mixture: [
      //     { name: "Halo", value: "2550ml" },
      //     { name: "BioControl", value: "950ml" },
      //     { name: "ISO", value: "100ml" },
      //     { name: "PH", value: "5.8-6.2" },
      //   ],
      //   preventativeDays: 3,
      //   reactiveDays: 3,
      // },
    },


    //stop and review here
    "19": {
      "25000": {
        title: "T-250, using Mammoth CannControl (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "CannControl", value: "60ml" },
          { name: "ISO", value: "40ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Mammoth CannControl (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "CannControl", value: "60ml" },
          { name: "ISO", value: "40ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      // "50000": {
      //   title: "T-250, using Mammoth CannControl (every 5 days)",
      //   sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      //   mixture: [
      //     { name: "Halo", value: "3250ml" },
      //     { name: "CannControl", value: "300ml" },
      //     { name: "ISO", value: "50ml" },
      //     { name: "PH", value: "5.8-6.2" },
      //   ],
      //   preventativeDays: 5,
      //   reactiveDays: 0,
      // },
    },
    "20": {
      "25000": {
        title: "T-250, using Mammoth CannControl (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "CannControl", value: "60ml" },
          { name: "ISO", value: "40ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Mammoth CannControl (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "CannControl", value: "60ml" },
          { name: "ISO", value: "40ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      // "50000": {
      //   title: "T-250, using Mammoth CannControl (every 3 days)",
      //   sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      //   mixture: [
      //     { name: "Halo", value: "2550ml" },
      //     { name: "CannControl", value: "950ml" },
      //     { name: "ISO", value: "100ml" },
      //     { name: "PH", value: "5.8-6.2" },
      //   ],
      //   preventativeDays: 3,
      //   reactiveDays: 3,
      // },
    },

    "21": {
      "25000": {
        title: "T-250, using Athena IPM (apply daily with a day break every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Athena IPM", value: "60ml" },
          { name: "ISO", value: "40ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 1,
        reactiveDays: 1,
      },
      "50000": {
        title: "T-250, using Athena IPM (apply daily with a day break every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3250ml" },
          { name: "Athena IPM", value: "300ml" },
          { name: "ISO", value: "50ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 1,
        reactiveDays: 1,
      },
    },
    "22": {
      "25000": {
        title: "T-250, using Athena IPM (apply daily with a day break every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Athena IPM", value: "300ml" },
          { name: "ISO", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 1,
        reactiveDays: 1,
      },
      "50000": {
        title: "T-250, using Athena IPM (apply daily with a day break every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Athena IPM", value: "950ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 1,
        reactiveDays: 1,
      },
    },
    "23": {
      "25000": {
        title: "T-250, using Growsafe (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Growsafe", value: "60ml" },
          { name: "ISO", value: "40ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Growsafe (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3200ml" },
          { name: "Growsafe", value: "300ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "24": {
      "25000": {
        title: "T-250, using Growsafe (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Growsafe", value: "300ml" },
          { name: "ISO", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Growsafe (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Growsafe", value: "950ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "25": {
      "25000": {
        title: "T-250, using Wizards Brew Concentrate (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Wizard Brew", value: "50ml" },
          { name: "ISO", value: "25ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Wizards Brew Concentrate (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3250ml" },
          { name: "Wizard Brew", value: "250ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "26": {
      "25000": {
        title: "T-250, using Wizards Brew Concentrate (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Wizard Brew", value: "300ml" },
          { name: "ISO", value: "50ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Wizards Brew Concentrate (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Wizard Brew", value: "950ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "27": {
      "25000": {
        title: "T-250, using TetraCurb (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Tetra Curb", value: "50ml" },
          { name: "ISO", value: "25ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using TetraCurb (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3250ml" },
          { name: "Tetra Curb", value: "250ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "28": {
      "25000": {
        title: "T-250, using TetraCurb (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Tetra Curb", value: "300ml" },
          { name: "ISO", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using TetraCurb (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Tetra Curb", value: "950ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "29": {
      "25000": {
        title: "T-250, using Organishield (every 10 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Organishield", value: "50ml" },
          { name: "ISO", value: "25ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 10,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Organishield (every 10 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3250ml" },
          { name: "Organishield", value: "250ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 10,
        reactiveDays: 0,
      },
    },
    "30": {
      "25000": {
        title: "T-250, using Organishield (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Organishield", value: "300ml" },
          { name: "ISO", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
      "50000": {
        title: "T-250, using Organishield (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Organishield", value: "950ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
    },

    "31": {
      "25000": {
        title: "T-250, using Plant Therapy (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Plant Therapy", value: "60ml" },
          { name: "H2O", value: "40ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Plant Therapy (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3250ml" },
          { name: "Plant Therapy", value: "250ml" },
          { name: "H2O", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "32": {
      "25000": {
        title: "T-250, using Plant Therapy (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Plant Therapy", value: "300ml" },
          { name: "H2O", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Plant Therapy (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Plant Therapy", value: "950ml" },
          { name: "H2O", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "33": {
      "25000": {
        title: "T-250, using Green Cleaner (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Green Cleaner", value: "60ml" },
          { name: "H2O", value: "40ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Green Cleaner (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3250ml" },
          { name: "Green Cleaner", value: "250ml" },
          { name: "H2O", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "34": {
      "25000": {
        title: "T-250, using Green Cleaner (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Green Cleaner", value: "300ml" },
          { name: "H2O", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Green Cleaner (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Green Cleaner", value: "950ml" },
          { name: "H2O", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "35": {
      "25000": {
        title: "T-250, using Dr. Zymes (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Dr Zymes", value: "50ml" },
          { name: "H2O", value: "25ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Dr. Zymes (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3250ml" },
          { name: "Dr Zymes", value: "250ml" },
          { name: "H2O", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "36": {
      "25000": {
        title: "T-250, using Dr. Zymes (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Dr Zymes", value: "300ml" },
          { name: "H2O", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Dr. Zymes (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Dr Zymes", value: "950ml" },
          { name: "H2O", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "37": {
      "25000": {
        title: "T-250, using Flying Skull Nuke Em (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1050ml" },
          { name: "Nuke em", value: "120ml" },
          { name: "ISO", value: "80ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Flying Skull Nuke Em (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3100ml" },
          { name: "Nuke em", value: "400ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "38": {
      "25000": {
        title: "T-250, using Flying Skull Nuke Em (every 2 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Nuke em", value: "325ml" },
          { name: "ISO", value: "50ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 2,
        reactiveDays: 2,
      },
      "50000": {
        title: "T-250, using Flying Skull Nuke Em (every 2 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Nuke em", value: "950ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 2,
        reactiveDays: 2,
      },
    },
    "39": {
      "25000": {
        title: "T-250, using Dr. Bronners Peppermint (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Dr Bronners", value: "75ml" },
          { name: "H2O", value: "25ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Dr. Bronners Peppermint (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3125ml" },
          { name: "Dr Bronners", value: "375ml" },
          { name: "H2O", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "40": {
      "25000": {
        title: "T-250, using Dr. Bronners Peppermint (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Dr Bronners", value: "300ml" },
          { name: "H2O", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Dr. Bronners Peppermint (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Dr Bronners", value: "950ml" },
          { name: "H2O", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "41": {
      "25000": {
        title: "T-250, using Mpede (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Mpede", value: "75ml" },
          { name: "ISO", value: "25ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Mpede (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3125ml" },
          { name: "Mpede", value: "375ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "42": {
      "25000": {
        title: "T-250, using Mpede (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Mpede", value: "300ml" },
          { name: "ISO", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Mpede (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Mpede", value: "950ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
  },
  "t500": {
    // ... Similar structure for T-500

    "1": {
      "25000": {
        title: "T-500, using ISO Spray (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "ISO", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using ISO Spray (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "ISO", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "2": {
      "25000": {
        title: "T-500, using ISO Spray (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "ISO", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using ISO Spray (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "ISO", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "3": {
      "25000": {
        title: "T-500, using ISO Spray (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "ISO", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using ISO Spray (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "ISO", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "4": {
      "25000": {
        title: "T-500, using H2O2 Spray (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "H2O2", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using H2O2 Spray (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "H2O2", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "5": {
      "25000": {
        title: "T-500, using H2O2 Spray (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "H2O2", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using H2O2 Spray (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "H2O2", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "6": {
      "25000": {
        title: "T-500, using H2O2 Spray (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "H2O2", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using H2O2 Spray (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "H2O2", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "7": {
      "25000": {
        title: "T-500, using Green Cleaner Spray (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "Green Cleaner", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Green Cleaner Spray (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "Green Cleaner", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "8": {
      "25000": {
        title: "T-500, using Green Cleaner Spray (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Green Cleaner", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Green Cleaner Spray (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Green Cleaner", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "9": {
      "25000": {
        title: "T-500, using Green Cleaner Spray (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "Green Cleaner", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using Green Cleaner Spray (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "Green Cleaner", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "10": {
      "25000": {
        title: "T-500, using Dr. Zymes Spray (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "Dr Zymes", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Dr. Zymes Spray (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "Dr Zymes", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },

"11": {
      "25000": {
        title: "T-500, using Dr. Zymes Spray (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Dr Zymes", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Dr. Zymes Spray (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Dr Zymes", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },

    "12": {
      "25000": {
        title: "T-500, using Dr. Zymes Spray (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "Dr Zymes", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using Dr. Zymes Spray (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "Dr Zymes", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "13": {
      "25000": {
        title: "T-500, using Plant Therapy (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "Plant Therapy", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Plant Therapy (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "Plant Therapy", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "14": {
      "25000": {
        title: "T-500, using Plant Therapy (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Plant Therapy", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Plant Therapy (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Plant Therapy", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "15": {
      "25000": {
        title: "T-500, using Plant Therapy (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "Plant Therapy", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using Plant Therapy (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "Plant Therapy", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "16": {
      "25000": {
        title: "T-500, using Flying Skull Nuke Em (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "Nuke em", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Flying Skull Nuke Em (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "Nuke em", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "17": {
      "25000": {
        title: "T-500, using Flying Skull Nuke Em (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Nuke em", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Flying Skull Nuke Em (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Nuke em", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "18": {
      "25000": {
        title: "T-500, using Flying Skull Nuke Em (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "Nuke em", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using Flying Skull Nuke Em (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "Nuke em", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "19": {
      "25000": {
        title: "T-500, using Dr. Bronners Peppermint (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "Dr Bronners", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Dr. Bronners Peppermint (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "Dr Bronners", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "20": {
      "25000": {
        title: "T-500, using Dr. Bronners Peppermint (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Dr Bronners", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Dr. Bronners Peppermint (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Dr Bronners", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      

      
    },
  },
  "pro1500": {
    // ... Similar structure for Pro-1500
    "1": {
      "50000": {
        title: "Pro-1500, using ISO Spray (every 7 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "ISO", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "100000": {
        title: "Pro-1500, using ISO Spray (every 7 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "11200ml" },
          { name: "ISO", value: "1800ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "2": {
      "50000": {
        title: "Pro-1500, using ISO Spray (every 5 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "ISO", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "100000": {
        title: "Pro-1500, using ISO Spray (every 5 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "8800ml" },
          { name: "ISO", value: "4200ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "3": {
      "50000": {
        title: "Pro-1500, using ISO Spray (every 3 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "ISO", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "100000": {
        title: "Pro-1500, using ISO Spray (every 3 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "7000ml" },
          { name: "ISO", value: "6000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "4": {
      "50000": {
        title: "Pro-1500, using H2O2 Spray (every 7 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "H2O2", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "100000": {
        title: "Pro-1500, using H2O2 Spray (every 7 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "11200ml" },
          { name: "H2O2", value: "1800ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "5": {
      "50000": {
        title: "Pro-1500, using H2O2 Spray (every 5 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "H2O2", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "100000": {
        title: "Pro-1500, using H2O2 Spray (every 5 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "8800ml" },
          { name: "H2O2", value: "4200ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "6": {
      "50000": {
        title: "Pro-1500, using H2O2 Spray (every 3 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "H2O2", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "100000": {
        title: "Pro-1500, using H2O2 Spray (every 3 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "7000ml" },
          { name: "H2O2", value: "6000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "7": {
      "50000": {
        title: "Pro-1500, using Green Cleaner Spray (every 7 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "Green Cleaner", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "100000": {
        title: "Pro-1500, using Green Cleaner Spray (every 7 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "11200ml" },
          { name: "Green Cleaner", value: "1800ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "8": {
      "50000": {
        title: "Pro-1500, using Green Cleaner Spray (every 5 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Green Cleaner", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "100000": {
        title: "Pro-1500, using Green Cleaner Spray (every 5 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "8800ml" },
          { name: "Green Cleaner", value: "4200ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "9": {
      "50000": {
        title: "Pro-1500, using Green Cleaner Spray (every 3 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "Green Cleaner", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "100000": {
        title: "Pro-1500, using Green Cleaner Spray (every 3 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "7000ml" },
          { name: "Green Cleaner", value: "6000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "10": {
      "50000": {
        title: "Pro-1500, using Dr. Zymes Spray (every 7 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "Dr Zymes", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "100000": {
        title: "Pro-1500, using Dr. Zymes Spray (every 7 days)",
        sizeArea: ["50,000 Cubic Ft.", "100,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "11200ml" },
          { name: "Dr Zymes", value: "1800ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },

      "11": {
      "25000": {
        title: "Pro-1500, using Dr. Zymes Spray (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Dr Zymes", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Dr. Zymes Spray (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Dr Zymes", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "12": {
      "25000": {
        title: "Pro-1500, using Dr. Zymes Spray (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "Dr Zymes", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Dr. Zymes Spray (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "Dr Zymes", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "13": {
      "25000": {
        title: "Pro-1500, using Plant Therapy (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "Plant Therapy", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Plant Therapy (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "Plant Therapy", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "14": {
      "25000": {
        title: "Pro-1500, using Plant Therapy (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Plant Therapy", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Plant Therapy (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Plant Therapy", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "15": {
      "25000": {
        title: "Pro-1500, using Plant Therapy (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "Plant Therapy", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Plant Therapy (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "Plant Therapy", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "16": {
      "25000": {
        title: "Pro-1500, using Flying Skull Nuke Em (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "Nuke em", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Flying Skull Nuke Em (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "Nuke em", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "17": {
      "25000": {
        title: "Pro-1500, using Flying Skull Nuke Em (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Nuke em", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Flying Skull Nuke Em (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Nuke em", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "18": {
      "25000": {
        title: "Pro-1500, using Flying Skull Nuke Em (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "Nuke em", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Flying Skull Nuke Em (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "Nuke em", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "19": {
      "25000": {
        title: "Pro-1500, using Dr. Bronners Peppermint (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "Dr Bronners", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Dr. Bronners Peppermint (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "Dr Bronners", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "20": {
      "25000": {
        title: "Pro-1500, using Dr. Bronners Peppermint (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Dr Bronners", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Dr. Bronners Peppermint (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Dr Bronners", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
  },
  "pro4000": {
    // ... Similar structure for Pro-1500
    "1": {
      "25000": {
        title: "Pro-4000, using ISO Spray (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "ISO", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using ISO Spray (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "ISO", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "2": {
      "25000": {
        title: "Pro-4000, using ISO Spray (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "ISO", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using ISO Spray (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "ISO", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "3": {
      "25000": {
        title: "Pro-4000, using ISO Spray (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "ISO", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using ISO Spray (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "ISO", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "4": {
      "25000": {
        title: "Pro-4000, using H2O2 Spray (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "H2O2", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using H2O2 Spray (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "H2O2", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "5": {
      "25000": {
        title: "Pro-4000, using H2O2 Spray (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "H2O2", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using H2O2 Spray (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "H2O2", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "6": {
      "25000": {
        title: "Pro-4000, using H2O2 Spray (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "H2O2", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using H2O2 Spray (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "H2O2", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "7": {
      "25000": {
        title: "Pro-4000, using Green Cleaner Spray (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "Green Cleaner", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Green Cleaner Spray (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "Green Cleaner", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "8": {
      "25000": {
        title: "Pro-4000, using Green Cleaner Spray (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Green Cleaner", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Green Cleaner Spray (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Green Cleaner", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "9": {
      "25000": {
        title: "Pro-4000, using Green Cleaner Spray (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "Green Cleaner", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Green Cleaner Spray (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "Green Cleaner", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "10": {
      "25000": {
        title: "Pro-4000, using Dr. Zymes Spray (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "Dr Zymes", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Dr. Zymes Spray (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "Dr Zymes", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "11": {
      "25000": {
        title: "Pro-4000, using Dr. Zymes Spray (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Dr Zymes", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Dr. Zymes Spray (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Dr Zymes", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "12": {
      "25000": {
        title: "Pro-4000, using Dr. Zymes Spray (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "Dr Zymes", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Dr. Zymes Spray (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "Dr Zymes", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "13": {
      "25000": {
        title: "Pro-4000, using Plant Therapy (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "Plant Therapy", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Plant Therapy (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "Plant Therapy", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "14": {
      "25000": {
        title: "Pro-4000, using Plant Therapy (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Plant Therapy", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Plant Therapy (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Plant Therapy", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "15": {
      "25000": {
        title: "Pro-4000, using Plant Therapy (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "Plant Therapy", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Plant Therapy (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "Plant Therapy", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "16": {
      "25000": {
        title: "Pro-4000, using Flying Skull Nuke Em (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "Nuke em", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Flying Skull Nuke Em (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "Nuke em", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "17": {
      "25000": {
        title: "Pro-4000, using Flying Skull Nuke Em (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Nuke em", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Flying Skull Nuke Em (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Nuke em", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "18": {
      "25000": {
        title: "Pro-4000, using Flying Skull Nuke Em (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "Nuke em", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Flying Skull Nuke Em (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "Nuke em", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "19": {
      "25000": {
        title: "Pro-4000, using Dr. Bronners Peppermint (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "Dr Bronners", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Dr. Bronners Peppermint (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "Dr Bronners", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "20": {
      "25000": {
        title: "Pro-4000, using Dr. Bronners Peppermint (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Dr Bronners", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Dr. Bronners Peppermint (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Dr Bronners", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },

    

    // may not be great

    "21": {
      "25000": {
        title: "Pro-4000, using Dr. Bronners Peppermint (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "Dr Bronners", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Dr. Bronners Peppermint (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "Dr Bronners", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "22": {
      "25000": {
        title: "Pro-4000, using Mpede (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Mpede", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Mpede (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Mpede", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "23": {
      "25000": {
        title: "Pro-4000, using Mpede (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "Mpede", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Mpede (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "Mpede", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "24": {
      "25000": {
        title: "Pro-4000, using Azagaurd (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "Azagaurd", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Azagaurd (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "Azagaurd", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "25": {
      "25000": {
        title: "Pro-4000, using Azagaurd (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Azagaurd", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Azagaurd (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Azagaurd", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "26": {
      "25000": {
        title: "Pro-4000, using Azamax (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "Azamax", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Azamax (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "Azamax", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "27": {
      "25000": {
        title: "Pro-4000, using Azamax (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Azamax", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Azamax (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Azamax", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "28": {
      "25000": {
        title: "Pro-4000, using Azatin (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "Azatin", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Azatin (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "Azatin", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "29": {
      "25000": {
        title: "Pro-4000, using Azatin (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Azatin", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Azatin (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Azatin", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "30": {
      "25000": {
        title: "Pro-4000, using Suffoil (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Suffoil-x", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Suffoil (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Suffoil-x", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "31": {
      "25000": {
        title: "Pro-4000, using Suffoil (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "Suffoil-x", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Suffoil (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "Suffoil-x", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "32": {
      "25000": {
        title: "Pro-4000, using Tritek (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Tri-Tek", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Tritek (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Tri-Tek", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "33": {
      "25000": {
        title: "Pro-4000, using Tritek (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "Tri-Tek", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Tritek (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "Tri-Tek", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "34": {
      "25000": {
        title: "Pro-4000, using Big Time Exterminator (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Big Time", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Big Time Exterminator (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Big Time", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "35": {
      "25000": {
        title: "Pro-4000, using Big Time Exterminator (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "Big Time", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Big Time Exterminator (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "Big Time", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "36": {
      "25000": {
        title: "Pro-4000, using Smite (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "Smite", value: "450ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Smite (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "5600ml" },
          { name: "Smite", value: "900ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "37": {
      "25000": {
        title: "Pro-4000, using Smite (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "Smite", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Smite (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "Smite", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "38": {
      "25000": {
        title: "Pro-4000, using SNS 203 (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "SNS 203", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using SNS 203 (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "SNS 203", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "39": {
      "25000": {
        title: "Pro-4000, using SNS 203 (every 2 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1400ml" },
          { name: "SNS 203", value: "1850ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 2,
        reactiveDays: 2,
      },
      "50000": {
        title: "Pro-4000, using SNS 203 (every 2 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2800ml" },
          { name: "SNS 203", value: "3700ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 2,
        reactiveDays: 2,
      },
    },
    "40": {
      "25000": {
        title: "Pro-4000, using Mammoth Biocontrol (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "BioControl", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Mammoth Biocontrol (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "BioControl", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "41": {
      "25000": {
        title: "Pro-4000, using Mammoth Biocontrol (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1750ml" },
          { name: "BioControl", value: "1500ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Mammoth Biocontrol (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "3500ml" },
          { name: "BioControl", value: "3000ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "42": {
      "25000": {
        title: "Pro-4000, using Mammoth CannControl (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2200ml" },
          { name: "CannControl", value: "1050ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Mammoth CannControl (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "4400ml" },
          { name: "CannControl", value: "2100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
},
}




// export const getResultData = (mixture: string, dropdownOption: string, areaSize: string): ResultData => {
//   const mixtureId = mixture.includes("7 days") ? "1" : "2";
//   return resultDataMap[dropdownOption][mixtureId][areaSize];
// };


export const getResultData = (mixture: string, dropdownOption: string, areaSize: string): ResultData => {
  const mixtureId = mixture.includes("7 days") ? "1" : "2";
  
  if (resultDataMap[dropdownOption] && 
      resultDataMap[dropdownOption][mixtureId]) {
    
    const data25k = resultDataMap[dropdownOption][mixtureId]["25000"];
    const data50k = resultDataMap[dropdownOption][mixtureId]["50000"];

    // If both sizes have data, return the requested size
    if (data25k && data50k) {
      return resultDataMap[dropdownOption][mixtureId][areaSize];
    }
    
    // If either size is missing, return the fallback
    if (!data25k || !data50k) {
      return getFallbackData();
    }
  }
  
  // Fallback message when no data is available
  return getFallbackData();
};

const getFallbackData = (): ResultData => ({
  title: "No data available, please contact Neutrafog for further recommendation",
  sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."], // Default size areas
  mixture: [{ name: "Recommendation", value: "Please contact Neutrafog at support@neutrafog.com" }],
  preventativeDays: 0,
  reactiveDays: 0,
});

// this version works but gets weird click both
// export const getResultData = (mixture: string, dropdownOption: string, areaSize: string): ResultData => {
//   const mixtureId = mixture.includes("7 days") ? "1" : "2";
  
//   if (resultDataMap[dropdownOption] && 
//       resultDataMap[dropdownOption][mixtureId] && 
//       resultDataMap[dropdownOption][mixtureId][areaSize]) {
//     return resultDataMap[dropdownOption][mixtureId][areaSize];
//   } else {
//     // Fallback message when no data is available
//     return {
//       title: "No data available",
//       sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."], // Default size areas
//       mixture: [{ name: "Recommendation", value: "Please contact Neutrafog at support@neutrafog.com for further recommendation." }],
//       preventativeDays: 0,
//       reactiveDays: 0,
//     };
//   }
// };

export default dummyData;